import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faHeart, 
  faInfoCircle, 
  faCode, 
  faSuitcase, 
  faMoon, 
  faSpinner, 
  faBars,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons'
import { 
  faTwitter, 
  faGithub, 
  faLinkedin, 
  faHtml5, 
  faJs, 
  faPhp, 
  faCss3, 
  faPython, 
  faNodeJs, 
  faVuejs, 
  faReact,
  faDocker,
  faGitlab,
  faBitbucket
 } from '@fortawesome/free-brands-svg-icons'

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n'

library.add(
  faHeart, 
  faInfoCircle, 
  faCode, 
  faSuitcase, 
  faMoon, 
  faSpinner, 
  faBars,
  faArrowDown,
  faTwitter, 
  faGithub, 
  faLinkedin, 
  faHtml5, 
  faJs, 
  faPhp, 
  faCss3, 
  faPython, 
  faNodeJs, 
  faVuejs, 
  faReact,
  faDocker,
  faGitlab,
  faBitbucket
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
