import React, { Suspense, lazy } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './App.css';
// import Home from './components/Home.js'
const Home = lazy(() => import('./components/Home'));

function App() {
  return (
    <Suspense fallback={(<div className="loading"><FontAwesomeIcon icon="spinner" size="3x" spin /></div>)}>
      <div className="App">
          <Home />
      </div>
    </Suspense>
  );
}

export default App;
